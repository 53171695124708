<template>
    <div class="mobile">
        <!-- <div class="header">
            <img src="../../assets/img/mobile/2023/logo.png" alt="">
        </div> -->
        <Header class="header" />
        <div class="part-one">
            <img class="banner" src="../../assets/img/mobile/2023/mascot.png" alt="">
            <img
      v-show="!showPromotional"
        class="play"
        @click="playVideo()"
        src="../../assets/img/mobile/2023/pc/play.png"
        alt=""
      />
        </div>
        <div class="part-two">
            <img class="title" src="../../assets/img/mobile/2023/title1.png" alt="">
            <img class="ball1" src="../../assets/img/mobile/2023/ball1.png" alt="">

            <transition name="fade">
                <div class="video-play" v-show="gifList[0].showVideo">
                    <!-- <video muted autoplay="autoplay" loop="loop" :src="gifList[0].path"></video> -->
                    <img :src="gifList[0].path" alt="">
                </div>
            </transition>
            <transition name="fade">
                <div class="video-play" v-show="gifList[1].showVideo">
                    <!-- <video muted autoplay="autoplay" loop="loop" :src="gifList[1].path"></video> -->
                    <img :src="gifList[1].path" alt="">
                </div>
            </transition>
            <transition name="fade">
                <div class="video-play" v-show="gifList[2].showVideo">
                    <!-- <video muted autoplay="autoplay" loop="loop" :src="gifList[2].path"></video> -->
                    <img :src="gifList[2].path" alt="">
                </div>
            </transition>

            <!-- 不要在<transition-group> children上使用v-for index作为键，这与不使用键是一样的。 -->
            <!-- <transition-group name="fade">
                <div v-for="(item, index) in gifList" :key="index" class="video-play" v-show="item.showVideo">
                    <img :src="item.path" alt="">
                </div>
            </transition-group> -->

            <img class="picture" src="../../assets/img/mobile/2023/phone.png" alt="">
            <img class="ornament" src="../../assets/img/mobile/2023/ornament.png" alt="">


            <div class="img-List">
                <div :class="item.showArrow ? 'img-item-choose' : 'img-item'" v-for="(item, index) in imgList"
                    :key="index" @click="chooseThis(item, index)">
                    <div class="img-overflow">
                        <img :src="item.path" alt="">
                        <div v-show="!item.showArrow" class="shade"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="part-three">
            <img class="title" src="../../assets/img/mobile/2023/title2.png" alt="">
            <img class="double" src="../../assets/img/mobile/2023/double.png" alt="">
            <img class="ball2" src="../../assets/img/mobile/2023/ball2.png" alt="">
        </div>
        <Downmost class="downmost" :type="go"></Downmost>
        <div class="downloadbg"></div>
        <Downloadbtn class="downloadbtn" type="mobile" />
    <transition name="fade">
      <div class="video-box" v-show="showPromotional">
        <div class="video-shade" @click="closeVideo"></div>
        <video
          id="my-video"
          class="video-player"
          src="../../assets/img/mobile/2023/pc/promotional.mp4"
          controls
        ></video>
      </div>
    </transition>
    </div>
</template>

<script>
import Downloadbtn from './downloadbtn.vue'
import Header from './header.vue'
import Downmost from './downmost.vue'
import { init } from './utils';
export default {
    components: { Downloadbtn, Header, Downmost },
    data() {
        return {
            imgList: [
                {
                    showArrow: true,
                    path: require(`../../assets/img/mobile/2023/pc/1.jpg`)
                },
                {
                    showArrow: false,
                    path: require(`../../assets/img/mobile/2023/pc/2.jpg`)
                },
                {
                    showArrow: false,
                    path: require(`../../assets/img/mobile/2023/pc/3.jpg`)
                },
            ],
            gifList: [
                {
                    showVideo: true,
                    path: 'http://pcsource.upupoo.com/official_website/gif/1.webp'
                },
                {
                    showVideo: false,
                    path: 'http://pcsource.upupoo.com/official_website/gif/2.webp'
                },
                {
                    showVideo: false,
                    path: 'http://pcsource.upupoo.com/official_website/gif/3.webp'
                },
            ],
            go: {
                name: '关于我们',
                link: './about2'
            },
      showPromotional: false, // 播放宣传视频
        }
    },
    methods: {
    // 播放视频
    playVideo() {
      // console.log("play video");
      this.showPromotional = true;
      let v = document.getElementById("my-video");
      v.play();
      window.addEventListener("touchmove", this.handleStopWheel, {
        passive: false,
      });
    },
    closeVideo() {
      this.showPromotional = false;
      let v = document.getElementById("my-video");
      v.pause();
      v.currentTime = 0;
      window.removeEventListener("touchmove", this.handleStopWheel)
    },
    // 禁止手指头划的滚动，滚轮可以但无伤大雅 ~
    handleStopWheel(e) {
      e.preventDefault();
    },
        // 选择某个图片
        chooseThis(item, index) {
            for (let i = 0; i < this.imgList.length; i++) {
                this.imgList[i].showArrow = false
                this.gifList[i].showVideo = false
            }
            item.showArrow = true
            this.gifList[index].showVideo = true
        },
    },
    mounted() {
        init();
    }
}
</script>

<style lang="scss" scoped>
.mobile {
    position: relative;
    width: 100%;
    overflow-x: hidden;

    .header {
        position: fixed;
        top: 0;
        left: 0;
    }

    .part-one {
        position: relative;
        // top: 78px;
        margin-top: 78px;
        width: 100%;
        // height: 453px;

        .banner {
      width: 100%;
      // min-height: 453px;
    }
    .play {
      position: absolute;
      top: 48.12%;
      left: 38.67%;
      width: 21.87%;
    }
    }

    .title {
        position: relative;
        transform: translate(-50%, 0);
        left: 50%;
        width: 70.93%;
        z-index: 1;
    }

    .part-two {
        --width-picture: 57.87%;
        position: relative;
        // top: 38px;
        margin-top: 38px;
        width: 100%;
        // min-height: 566px;

        .picture {
            position: absolute;
            margin-top: 58px;
            margin-left: 10.4%;
            width: var(--width-picture); // *1.78是高
            // z-index: 1;
        }

        .ornament {
            position: absolute;
            margin-top: 28px;
            margin-left: 1.07%;
            width: 19.33%;
            // min-height: 89px;
            // z-index: 2;
        }

        .ball1 {
            position: absolute;
            bottom: -9%;
            left: 0;
            width: 36.93%;
        }

        .video-play {
            position: absolute;
            margin-top: calc(58px + 2%);
            left: 12%;
            width: 55%;
            // height: 67%;
            // min-height: 370px;
            // z-index: 6;

            video {
                width: 100%;
                height: 100%;
                // object-fit: fill;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        .img-List {
            position: relative;
            width: 21.2%;
            // min-height: 386px;
            // height: calc(var(--width-picture) * 1.78);
            left: 69.7%;
            margin-top: 58px;
            overflow: hidden;

            .img-item {
                box-sizing: border-box;
                position: relative;
                width: calc(92.25% - 1%);
                // min-height: 124px;
                margin-left: 7.57%;
                // background: #000000;
                // opacity: 0.6;
                border: 3px solid rgba(178, 201, 255, 1);
                border-radius: 7px;
                margin-bottom: 7px;
                // cursor: pointer;
                overflow: hidden;
                transition: .2s;

                // &:hover .img-overflow>.shade {
                //     opacity: 0;
                //     transition: .1s;
                // }

                .img-overflow {
                    // width: 100%;
                    // min-height: 124px;
                    overflow: hidden;

                    img {
                        user-select: none;
                        pointer-events: none;
                        position: relative;
                        width: 100%;
                        // height: 100%;
                        // min-height: 124px;
                    }

                    .shade {
                        position: absolute;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        // min-height: 124px;
                        background: #000000;
                        opacity: 0.6;
                        // border-radius: 10px;
                        transition: .2s;
                    }


                }
            }

            .img-item-choose {
                box-sizing: border-box;
                position: relative;
                width: calc(92.25% - 1%);
                // min-height: 124px;
                margin-left: 7.57%;
                // background: #000000;
                // opacity: 0.6;
                border: 3px solid rgba(46, 52, 69, 1);
                border-radius: 7px;
                margin-bottom: 7px;
                // cursor: pointer;
                transition: .2s;

                &::after {
                    content: "";
                    position: absolute;
                    transform: translate(0, -50%);
                    top: 50%;
                    left: calc(-8% - 3px);
                    width: 8%;
                    height: 7.3%;
                    background: #2E3445;
                    clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 50%);
                }

                .img-overflow {
                    box-sizing: border-box;
                    position: relative;
                    top: 0;
                    right: 0;
                    // width: 100%;
                    // min-height: 124px;
                    border-radius: 4px;
                    overflow: hidden;

                    img {
                        position: relative;
                        width: 100%;
                        // min-height: 124px;
                    }
                }
            }
        }
    }

    .part-three {
        position: relative;
        margin-top: 61px;
        margin-bottom: 79px;

        .double {
            margin-top: 42px;
            position: relative;
            width: 86.4%;
            transform: translate(-50%, 0);
            left: 50%;
            z-index: 1;
        }

        .ball2 {
            position: absolute;
            width: 68%;
            top: 79px;
            left: 66%;
        }
    }

    .downmost {
        position: relative;
        // bottom: 80px;
        width: 100%;
        padding-bottom: 80px;
    }

    // .downloadbg-deep {
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100px;
    //     background: #EBF4FF;
    // }

    .downloadbg {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 71px;
        // background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        background: linear-gradient(0deg,
                #FFFFFF,
                rgba(255, 255, 255, 1) 50%,
                rgba(255, 255, 255, 0.5) 75%,
                rgba(255, 255, 255, 0) 100%);
        z-index: 9;
    }

    .downloadbtn {
        // position: fixed;
        // bottom: 1.93%;
        // bottom: 35px;
        // left: 12.8%;
        // width: 100%;
        // z-index: 9;
        position: absolute;
        bottom: 0;
        z-index:999;
        width: 100%;
    }
}


.video-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;

  .video-shade {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0000005e;
  }

  .video-player {
    position: absolute;
    max-width: 90%;
    max-height: 70%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
  }
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .2s;
}

.fade-enter-to,
.fade-leave {
    opacity: 1;
}
</style>